import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import KlaviyoForm from '../../components/utilities/klaviyo-form'

const OptIn = () => {
  return (
    <Layout className="sms-page">
      <Seo title="Opt in" noindex />
      <Container fluid className="bg-danger p-0">
        <Row className="g-0" style={{ minHeight: '80vh' }}>
          <Col md={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 4 }} className="d-flex align-items-center">
            <div className="p-1 p-sm-2 p-xl-3 mx-auto" style={{ maxWidth: '800px' }}>
              <KlaviyoForm code="YAWPFC" />
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default OptIn
